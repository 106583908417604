import React from 'react';
import{Box,Stack,Heading,VStack,HStack,Button,Input,Text} from "@chakra-ui/react";
import{AiOutlineSend} from "react-icons/ai"

const Footer = () => {
  return <Box bgColor={"blackAlpha.900"} minH={"40"} p="16" color={"white"}>

  <Stack direction={['column','row']}>
  
    <VStack alignItems={'stretch'} w={"full"} px={"4"} textAlign={["center","left"]}>
      <Heading size="md" textTransform={"uppercase"}> Subscribe to get updates</Heading>
      <HStack 
      borderBottom={'2px solid white'} 
      py={2}>
        <Input 
        placeholder='Enter email here...'
        border={"none"} 
        borderRadius={"none"} 
        outline={"none"} 
        focusBorderColor='none'/>
       <Button 
        p="0"
        colorScheme={'purple'}
        variant={"ghost"}
        borderRadius={'0 20px 20px 0'}>
      
        <AiOutlineSend size={20}/>
       </Button>

      </HStack>
    </VStack>

    <VStack 
    w={"full"} 
    borderLeft={["none","1px solid white"]}
    borderRight={["none","1px solid white"]}
    >
   <Heading  textTransform={"uppercase"} textAlign={"center"}>VIDEO HUB</Heading>
   <Text>All rights received</Text>
    </VStack>
    
    <VStack w={"full"}>

    <Heading size={"md"} textTransform={"uppercase"}>
      Social Media
    </Heading>

    <Button variant={"link"} colorScheme='whiteAlpha'>
      <a  href='https://youtube.com/Art Hub target={"blank"}'>Youtube</a>
    </Button>

    <Button variant={"link"} colorScheme='whiteAlpha'>
      <a  href='https://instagram.com/sonakiya_ayush'>Instagram</a>
    </Button>

    <Button variant={"link"} colorScheme='whiteAlpha'>
      <a href='https://github.com/sonakiya'>Github</a>
    </Button>
    </VStack>
   

  </Stack>

  </Box>
    
  
}

export default Footer